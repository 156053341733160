export const TRANSLATIONS = {
  en_US: {
    Username: "Username",
    Password: "Password",
    Forgot_password: "Forgot password?",
    Sign_in: "Sign in",
    Search: "Search",
    Dashboard: "Dashboard",
    Users: "Users",
    Save: "Save",
    Settings: "Settings",
    Buy_Credits: "Buy Credits",
    Get_File: "Get Files",
    Credits: "Credits",
    Available_Credits: "Available Credits",
    Number_of_Credits: "Number of Credits",
    Credits_Amount: "Credits Amount",
    Buy: "Buy",
    Upload_File: "Upload File",
    CreditsNeededToBuyFileHundeggerNc: "Credits Needed To Buy HundeggerNc: ",
    CreditsNeededToBuyFileHundeggerNcHam:
      "Credits Needed To Buy HundeggerNcHam: ",
    Approve: "Approve",
    Key: "Key",
    Value: "Value",
    Choose_File: "Choose File",
    Add_Settings: "Add Settings",
    PricePerCredit: "PricePerCredit",
    CreditPerTimber: "CreditPerTimber",
    CreditPerPlate: "CreditPerPlate",
    Price_per_credit: "Price per credit",
    Timber_tag: "Timber tag",
    Plate_tag: "Plate tag",
    Add_User: "Add User",
    FirstName: "FirstName",
    LastName: "LastName",
    Email: "Email",
    Active: "Active",
    State: "State",
    View_User: "View User",
    Edit_User: "Edit User",
    UserName: "UserName",
    Roles: "Roles",
    ConfirmPassword: "ConfirmPassword",
    Credit_History: "Credit History",
    CreateDate: "CreateDate",
    Creditsreductedoradded: "Creditsreductedoradded",
    Download_Link: "Download link",
    View_Link: "View Link",
    Success: "Success!",
    Expired: "Expired!",
    Cancelled: "Cancelled!",
    Failure: "Failure!",
    Success_m: "The payment is succesful!",
    Expired_m: "The payment is expired!",
    Failure_m: "The payment has failed!",
    Cancelled_m: "The payment has been cancelled!",
    Confirm: "Confirm",
    Are_you_sure: "Are you sure to do this?",
    Delete: "Delete",
    Cancel: "Cancel",
    OK: "OK",
    LogOut: "LogOut",
    Enter_email: "Enter email",
    Back_to_Sign_in: "Back to Sign in",
    Next_Step: "Next Step",
    Back_To_Admin: "Back To Admin",
    FileName: "FileName",
    New_Password: "New Password",
    Repeat: "Repeat",
    Edit: "Edit",
    Select: "Select",
    Project: "Project",
    OrderNumber: "OrderNumber",
    ProjectName: "ProjectName",
    GroupName: "GroupName",
    AppVersion: "AppVersion",
    Bouns_credits: "Bonus credits",
    Type_data_file: "Type data file",
    Action: "Action",
    Customer: "Customer",
    Add_Customer: "Add Customer",
    CustomerName: "CustomerName",
    Address: "Address",
    ZipCode: "ZipCode",
    City: "City",
    Country: "Country",
    AvailableCredits: "AvailableCredits",
    The_ustomer_has_been_deleted: "The_ustomer_has_been_deleted!",
    Edit_Customer: "Edit Customer",
    View_Customer: "View Customer",
    Previous: "Previous",
    Next: "Next",
    Show_entries: "Show entries",
    Show: "Show",
    Entries: "entries",
    Show_page: "Showing page",
    Company: "Company",
    User: "User",
    VatPercentage: "BTWPercentage",
    VAT_amount: "BTW Amount",
    Total_amount: "Total Amount",
    Purchase_credits: "Purchase credits",
    Not_enough_credits: "Not enough credits. Please buy credit!",
    Add_bonus: "AddBonus",
    Bonus: "Bouns",
    allPanels: "All panels",
    byPanelJoined: "By Panel joined",
  },

  nl_BE: {
    Username: "Gebruikersnaam",
    Password: "Wachtwoord",
    Forgot_password: "Wachtwoord vergeten?",
    Sign_in: "Aanmelden",
    Search: "Zoeken",
    Dashboard: "Dashboard",
    Users: "Gebruikers",
    Save: "Bewaren",
    Settings: "Instellingen",
    Buy_Credits: "Koop credits",
    Get_File: "Bestand ophalen",
    Credits: "Credits",
    Available_Credits: "Beschikbare Credits",
    Number_of_Credits: "Aantal credits",
    Credits_Amount: "Credits Bedrag",
    Buy: "Kopen",
    Upload_File: "Upload bestand",
    CreditsNeededToBuyFileHundeggerNc:
      "Benodigde credits om een plaatbestand te kopen: ",
    CreditsNeededToBuyFileHundeggerNcHam:
      "Benodigde credits om een zaagbestand te kopen: ",
    Approve: "Goedkeuren",
    Key: "Sleutel",
    Value: "Waarde",
    Choose_File: "Kies bestand",
    Add_Settings: "Instellingen toevoegen",
    PricePerCredit: "Prijs per credit",
    CreditPerTimber: "Credits per timber",
    CreditPerPlate: "Credit per plate",
    Price_per_credit: "Prijs per credit",
    Timber_tag: "Timber tag",
    Plate_tag: "Plate tag",
    Add_User: "Voeg gebruiker toe",
    FirstName: "Voornaam",
    LastName: "Achternaam",
    Email: "Email",
    Active: "Actief",
    State: "Staat",
    View_User: "Bekijk gebruiker",
    Edit_User: "Bewerk gebruiker",
    UserName: "Gebruikersnaam",
    Roles: "Rollen",
    ConfirmPassword: "Bevestig wachtwoord",
    Credit_History: "Credit geschiedenis",
    CreateDate: "Aanmaak datum",
    Creditsreductedoradded: "Credits verlaagd of toegevoegd",
    Download_Link: "Download link",
    View_Link: "Bekijk link",
    Success: "Succesvol!",
    Expired: "Verlopen!",
    Cancelled: "Geannuleerd!",
    Failure: "Mislukt!",
    Success_m: "De betaling is geslaagd!",
    Expired_m: "De betaling is verlopen!",
    Failure_m: "De betaling is mislukt!",
    Cancelled_m: "De betaling is geannuleerd!",
    Confirm: "Bevestigen",
    Are_you_sure: "Weet je zeker dat je dit wilt doen?",
    Delete: "Verwijderen",
    Cancel: "Annuleren",
    OK: "OK",
    LogOut: "Uitloggen",
    Enter_email: "Voer e-mail in",
    Back_to_Sign_in: "Terug naar inloggen",
    Next_Step: "Volgende stap",
    Back_To_Admin: "Terug naar Admin",
    FileName: "Bestandsnaam",
    New_Password: "Nieuw wachtwoord",
    Repeat: "Herhaling",
    Edit: "Bewerk",
    Select: "Kiezen",
    Project: "Project",
    OrderNumber: "Werknummer",
    ProjectName: "Project",
    GroupName: "Onderdeel",
    AppVersion: "AppVersion",
    Bouns_credits: "Bonus credits",
    Type_data_file: "Type bestand",
    Action: "Acties",
    Customer: "Klant",
    Add_Customer: "Toevoegen klant",
    CustomerName: "Klantnaam",
    Address: "Adres",
    ZipCode: "Postcode",
    City: "Stad",
    Country: "Land",
    AvailableCredits: "Beschikbare credits",
    The_ustomer_has_been_deleted: "De klant is verwijderd!",
    Edit_Customer: "Bewerk klant",
    View_Customer: "Bekijk klant",
    Previous: "Vorige",
    Next: "Volgende",
    Show_entries: "Toon records",
    Show: "Tonen",
    Entries: "records",
    Show_page: "Pagina weergegeven",
    Company: "Bedrijf",
    User: "Gebruiker",
    VatPercentage: "BTW percentage",
    VAT_amount: "BTW bedrag",
    Total_amount: "Totaal bedrag",
    Purchase_credits: "Aankoop credits",
    Not_enough_credits:
      "Niet genoeg credits. Koop nieuwe credits om verder te gaan!",
    Add_bonus: "ToevoegenBonus",
    Bonus: "Bonus",
    allPanels: "een bestand",
    byPanelJoined: "bestanden per kopmaat",
  },
};
