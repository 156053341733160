import { Link } from 'react-router-dom';
import React from 'react';
// import * as authAction  from '../../actions/authAction';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import ListErrors from '../../components/listerrors';
import { trls } from '../../components/translate';
import SessionManager from '../../components/session_manage';
import API from '../../components/api'
import Axios from 'axios';
import Success from '../../components/success';

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
});

class Forgotpassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {  
      success: '',
    };
  }
  handleSubmit = (event) => {
    event.preventDefault();
    const clientFormData = new FormData(event.target);
    const data = {};
    for (let key of clientFormData.keys()) {
        data[key] = clientFormData.get(key);
    }
    
    var params = {
        "email": data.email,
        "resetPasswordBaseUrl": "https://tekwoods.bijvoorbeeldzo.nl/reset-password"
      }
    var headers = SessionManager.shared().getAuthorizationHeader();
        Axios.post(API.PostForgotPassEmail, params, headers)
        .then(result => {
          this.setState({success: "Please check your email!"})
        });
  }
  render() {
    return (
      <div className="auth-page" style={{height:"100%"}}>
        <div className="container login-page">
          <div className="row addQuestion">
            <div className="col-md-5 offset-md-1 col-xs-12  vertical-center">
                <Row style={{height:"100%",width:"100%"}}>
                  <div className="login-side-div">
                    <img src={require('../../assets/images/img_admin_side.png')} alt="appzmakerz" className="login-side-grad"></img>
                  </div>
                  <Col  className="login-form-div">
                    <img src={require('../../assets/images/tekwoods_logo.png')} alt="appzmakerz" style={{marginTop:"70px", height: "100px"}}></img>
                     <form className="login-form" onSubmit = { this.handleSubmit }>
                     <ListErrors errors={this.props.error} />
                        <fieldset>  
                            <fieldset className="form-group">
                                <input type="email" name="email" className="orders__filters-search input-email" placeholder={trls("Enter_email")}/>
                            </fieldset>
                            <p className="text-xs-center">
                                <Link to="/login" style={{color:"rgb(84, 79, 79)"}}>
                                    {trls("Back_to_Sign_in")}
                                </Link>
                            </p>
                            <div style={{margin:"auto", width: "60%"}}>
                              <Success message={this.state.success}/>
                            </div>
                            <button type="submit" className="btn-small place-and-orders__add-row-btn add-row sign-in">{trls("Next_Step")}</button>
                        </fieldset>
                    </form>
                  </Col>
                </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Forgotpassword);
