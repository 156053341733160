import React from 'react';

class Success extends React.Component {
  
  render() {
    const message = this.props.message;
    if (message) {
      return (
        <div className="alert alert-success list-error" style={this.props.style}>
            <strong><i className="fas fa-check-circle" style={{marginRight: 10}}></i>{message}</strong>
        </div>
      );
    } else {
      return null;
    }
  }
}
export default Success;