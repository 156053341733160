export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
export const FETCH_LOGIN_FAIL = 'FETCH_LOGIN_FAIL';
export const FETCH_SERVER_FAIL = 'FETCH_SERVER_FAIL';
export const FETCH_BlANK_DATA = 'FETCH_BlANK_DATA';
export const FETCH_SALES_GETDATA = 'FETCH_SALES_GETDATA';
export const FETCH_CUSTOMER_DATA = 'FETCH_CUSTOMER_DATA';
export const FETCH_SALES_SAVEDATA = 'FETCH_SALES_SAVEDATA';
export const FETCH_GETSALES_DATA = 'FETCH_GETSALES_DATA';
export const FETCH_GETSALESITEM_DATA = 'FETCH_GETSALESITEM_DATA';
export const FETCH_LANGUAGE_DATA = 'FETCH_LANGUAGE_DATA';
export const FETCH_LOGINAS = 'FETCH_LOGINAS';
export const FETCH_GO_AUTH_USER = 'FETCH_GO_AUTH_USER';
export const FETCH_PAGE_LOADING = 'FETCH_PAGE_LOADING';